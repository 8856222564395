$(document).on('click', '.btn-contact', (e) => {
  $('body').addClass('modal-show');
  $('.modal').show()
});

$(document).on('click', '.modal .close', (e) => {
  $('body').removeClass('modal-show');
  $('.modal').hide()
});


$(document).on('click', '.btn-message', () => {
  $.ajax({
    method: "POST",
    url: "mail.php",
    data: $('#contact').serializeArray()
  })
    .done(function( data ) {
      if(data == 1) {
        $('#contact').html(`Thank you for contacting us we will get in touch with you shortly.`)
      }
    });
});
